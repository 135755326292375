import React from "react"
import SEO from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header"
import Footer from "../containers/layout/footer"
import { useStaticQuery, graphql } from "gatsby"
import PageTitleArea from "../containers/page-areas/page-title-area"
import PageBannerArea from "../containers/page-areas/page-banner-area"
import PageContentArea from "../containers/page-areas/page-content-area"
import InstagramWrap from "../containers/global/instagram"

const ContributePage = () => {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "envyforge-spark-persona-app-banner" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <SEO title="Contribute | Envy Forge" />
      <Header />
      <div className="main-content">
        <PageTitleArea
          title="Contribute to Envy Forge"
          tagline="Let's work together to get the right products to market and grow, faster!"
        />
        <PageBannerArea
          bannerimage={file.childImageSharp.fluid}
          banneralt="contribute hero"
        />
        <PageContentArea
          heading="How you can contribute"
          content="Contribute to our open source initiatives and documentation by clicking on the edit icon at the top right of any page within our https://www.envyforge.com/docs path and you can submit a change request. We may incorporate it! But either way we very much appreciate your suggestions and editorial. We're all in this together."
        />
        <InstagramWrap />
      </div>
      <Footer />
    </Layout>
  )
}

export default ContributePage
